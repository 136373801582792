<template>
  <div :class="$style.wrap">
    <LayoutContent>
      <template v-slot:header-left>
        <span :class="$style.title">{{ $t('electricBox.deviceManage') }}</span>
      </template>
      <div :class="[$style.main, $style.content]" slot="content">
        <div :class="$style.topData">
          <div
            :class="$style.item"
            v-for="(item, idx) in topDataOptions"
            :key="idx"
            :style="{ backgroundColor: item.backgroundColor }"
            @click="filterDeviceList(item)"
          >
            <div :class="$style.textDiv">
              <div :class="$style.numberDiv">
                {{ countObj[item.number] }}
              </div>
              <div :class="$style.numberTextDiv">
                {{ item.text }}
              </div>
            </div>
            <div :class="$style.iconDiv" :style="{ boxShadow: item.boxShadow }">
              <x-icon
                :class="$style.iconStyle"
                :type="item.icon"
                :style="{ color: item.backgroundColor }"
              />
            </div>
          </div>
        </div>
        <div>
          <a-form-model :class="$style.search" :model="form">
            <div>
              <div :class="$style.item" style="width: 120px">
                <a-form-model-item prop="operationalStatus">
                  <a-select
                    v-model="form.operationalStatus"
                    :placeholder="$t('electricBox.select.status')"
                    allowClear
                    @change="() => handleSearchSubmit()"
                  >
                    <a-select-option
                      v-for="(item, idx) in statusOptions"
                      :key="idx"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>

                    <template slot="notFoundContent">
                      <a-empty :image="simpleImage" />
                    </template>
                  </a-select>
                </a-form-model-item>
              </div>
              <div :class="$style.item" style="width: 220px">
                <a-form-model-item>
                  <a-select
                    v-model="form.projectId"
                    v-if="form.tenantId !== undefined"
                    :placeholder="$t('electricBox.select.projects')"
                    allowClear
                    show-search
                    option-filter-prop="children"
                    :filter-option="false"
                    @change="handProjectChange"
                    @search="input => filterProjectOption(input)"
                  >
                    <a-select-option
                      v-for="item in projects"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </a-select-option>
                    <template slot="notFoundContent">
                      <a-empty :image="simpleImage" />
                    </template>
                  </a-select>
                  <a-input
                    v-else
                    v-model="form.projectName"
                    allow-clear
                    @change="() => handleSearchSubmit()"
                    :placeholder="$t('electricBox.select.projects')"
                  />
                </a-form-model-item>
              </div>
              <div :class="$style.item" style="width: 200px">
                <a-form-model-item>
                  <a-input
                    v-model="form.deviceName"
                    allow-clear
                    @change="() => handleSearchSubmit()"
                    :placeholder="$t('electricBox.select.deviceCode')"
                  />
                </a-form-model-item>
              </div>
            </div>
            <div :class="$style.btn">
              <a-button
                v-if="$p.action('CREATE')"
                type="primary"
                @click="bindDevice({})"
                >{{ $t('electricBox.select.add') }}</a-button
              >
              <a-button
                v-if="$p.action('WARNING_RULE')"
                type="primary"
                @click="warnSettings()"
                >{{ $t('electricBox.select.rules') }}</a-button
              >
              <a-button type="primary" @click="() => exportDevices()">{{
                $t('common.export')
              }}</a-button>
            </div>
          </a-form-model>
          <a-table
            :columns="columns"
            :data-source="tableData"
            :pagination="pagination"
            :scroll="{ x: 1600 }"
            :row-key="record => record.deviceId"
            :locale="emptyLocale"
            :loading="loading"
            @change="handlePagination"
          >
            <template slot="deviceSecret" slot-scope="text, record">
              <div>
                <span>{{ text }}</span>
                <x-icon
                  type="tc-icon-copy"
                  :class="$style.copy"
                  @click.stop="copy(record)"
                />
              </div>
            </template>

            <template slot="expireDate" slot-scope="text">
              {{ formatTime(text) }}
            </template>
            <template slot="operationalStatus" slot-scope="text, record">
              <span v-if="text === 'ONLINE'" :class="$style.statusOnline">
                {{ $t('electricBox.list.online') }}
              </span>
              <span v-if="text === 'OFFLINE'" :class="$style.statusOffline">
                {{ $t('electricBox.list.offline') }}
              </span>
              <span v-if="text === 'UN_BIND'" :class="$style.statusTobeBinding">
                {{ $t('electricBox.list.unbind') }}
              </span>
              <span v-if="text === 'BIND'" :class="$style.statusBind">
                {{ $t('electricBox.list.bind') }}
              </span>
              <a-tooltip placement="right">
                <template slot="title">
                  <span>{{ $t('electricBox.list.active') }}</span>
                </template>
                <a-icon
                  v-if="isBang(record) && record.isActivation"
                  :class="$style.actived"
                  type="check-circle"
                />
              </a-tooltip>
              <a-tooltip placement="right">
                <template slot="title">
                  <span>{{ $t('electricBox.list.unactive') }}</span>
                </template>
                <a-icon
                  v-if="isBang(record) && !record.isActivation"
                  :class="$style.notactived"
                  type="info-circle"
                />
              </a-tooltip>
              <span
                v-if="['LOCKED', 'SYSTEM_LOCKED'].indexOf(text) > -1"
                :class="$style.statusLocked"
              >
                {{ $t('electricBox.list.lock') }}
              </span>
            </template>
            <template slot="action" slot-scope="record">
              <span :class="$style.buttonGroups">
                <a
                  v-if="isShowBuild(record)"
                  :class="[$style.button]"
                  @click.stop="handleLink(record)"
                  >{{ $t('electricBox.btn.build') }}</a
                >
                <a
                  v-if="!isShowBuild(record)"
                  :class="[$style.button]"
                  @click.stop="bindDevice(record)"
                  >{{
                    record.operationalStatus === 'UN_BIND'
                      ? $t('electricBox.btn.bind')
                      : $t('electricBox.btn.edit')
                  }}</a
                >
                <a
                  :class="[$style.button]"
                  @click.stop="setConfiguration(record)"
                  >{{ $t('electricBox.btn.set') }}</a
                >
                <a :class="[$style.button]" @click.stop="manageBox(record)"
                  >{{ $t('electricBox.btn.manage')
                  }}<span
                    :class="$style.messageCount"
                    v-if="record.noticeAndAlarmMessageNumber"
                    >{{
                      record.noticeAndAlarmMessageNumber > 99
                        ? 99
                        : record.noticeAndAlarmMessageNumber
                    }}</span
                  ></a
                >
                <a
                  v-if="
                    (record.operationalStatus === 'LOCKED' ||
                      record.operationalStatus === 'SYSTEM_LOCKED') &&
                      record.operational
                  "
                  :class="[$style.button]"
                  @click.stop="unLock(record)"
                >
                  {{ $t('electricBox.btn.unlock') }}
                </a>
                <a
                  v-if="!isBang(record) && record.operational"
                  :class="[$style.button, $style.red]"
                  @click.stop="unBindOrRemove(record, 'unbind')"
                  >{{ $t('electricBox.btn.unbind') }}</a
                >
                <a
                  v-if="
                    record.operationalStatus === 'UN_BIND' &&
                      $p.action('UPDATE')
                  "
                  :class="[$style.button, $style.red]"
                  @click.stop="unBindOrRemove(record, 'remove')"
                >
                  {{ $t('electricBox.btn.remove') }}
                </a>
              </span>
            </template>
          </a-table>
        </div>
        <!-- <a-button
              type="primary"
              :disabled="selectedList.length === 0"
              @click="batchInform"
              >批量通知</a-button
            > -->
      </div>
    </LayoutContent>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import { createFormModal, createDrawer } from '@triascloud/x-components';
import ConditionSearch from '@/components/condition-search';
import Empty from '@/components/empty-content';
import BatchInform from './batch-inform';
import DeviceManage from './device-manage';
import DeviceConfiguration from './device-configuration';
import Tip from '@/components/tip';
import BindDevice from './bind-device';
import WarnSettingsForm from './warn-settings-form';
import { LayoutContent } from '@triascloud/x-blocks';
import {
  getDeviceList,
  deviceCounts,
  unBindDevice,
  deleteDevice,
  deviceLock,
  exportDeviceList,
} from '@/services/iot-electric-box/terminal-manage';
import { getRelatedProject } from '@/services/things/project.js';
import { backendGenerate } from '@/services/things/screen-manage';
import { clipboardWrite } from '@triascloud/utils';
import { createModuleUrl } from '@/utils';
import { crossStorageModule } from '@/enum/store.js';
import { Empty as EmptyObj } from 'ant-design-vue';

@Component({
  components: {
    ConditionSearch,
    Empty,
    DeviceManage,
    LayoutContent,
    WarnSettingsForm,
  },
})
export default class ElectricBoxManage extends Vue {
  beforeCreate() {
    this.simpleImage = EmptyObj.PRESENTED_IMAGE_SIMPLE;
  }
  @crossStorageModule.State('tenant') tenant;
  topDataOptions = [
    {
      number: 'registerNumber',
      text: this.$t('electricBox.list.deviceCount'), //'设备总数',
      icon: 'tc-icon-device',
      backgroundColor: '#947dfa',
      boxShadow: '-6px 0 0 4px #c9befc',
      value: undefined,
    },
    {
      number: 'onLineNumber',
      text: this.$t('electricBox.list.online'), //'在线',
      icon: 'tc-icon-device-online',
      backgroundColor: '#00b893',
      boxShadow: '-6px 0 0 4px #80dbc9',
      value: 'ONLINE',
    },
    {
      number: 'offLineNumber',
      text: this.$t('electricBox.list.offline'), //'离线',
      icon: 'tc-icon-device-offline',
      backgroundColor: '#fe5959',
      boxShadow: '-6px 0 0 4px #feacac',
      value: 'OFFLINE',
    },
    {
      number: 'unBindNumber',
      text: this.$t('electricBox.list.unbind'), //'待绑定',
      icon: 'tc-icon-device-warning',
      backgroundColor: '#ffa348',
      boxShadow: '-6px 0 0 4px #ffd1a3',
      value: 'UN_BIND',
    },
    {
      number: 'bindNumber',
      text: this.$t('electricBox.list.bind'), //'已绑定',
      icon: 'tc-icon-device-success',
      backgroundColor: '#516cf0',
      boxShadow: '-6px 0 0 4px #a8b5f7',
      value: 'BIND',
    },
  ];
  form = {
    tenantId: undefined,
    tenantName: undefined,
    projectId: undefined,
    projectName: undefined,
    operationalStatus: undefined,
    deviceName: '',
  };
  searchParams = {};
  statusOptions = [
    {
      label: this.$t('electricBox.list.online'),
      value: 'ONLINE',
    },
    {
      label: this.$t('electricBox.list.offline'),
      value: 'OFFLINE',
    },
    // {
    //   label: '待分配',
    //   value: 'UN_ASSIGNED',
    // },
    {
      label: this.$t('electricBox.list.unbind'),
      value: 'UN_BIND',
    },
    {
      label: this.$t('electricBox.list.bind'),
      value: 'BIND',
    },
    {
      label: this.$t('electricBox.list.lock'),
      value: 'LOCKED',
    },
  ];
  tenantOptions = [];
  isShowBuild(record) {
    return (
      ['ONLINE', 'OFFLINE', 'LOCKED', 'SYSTEM_LOCKED'].indexOf(
        record.operationalStatus,
      ) > -1
    );
  }
  isBang(record) {
    return (
      ['BIND', 'ONLINE', 'OFFLINE', 'LOCKED', 'SYSTEM_LOCKED'].indexOf(
        record.operationalStatus,
      ) === -1
    );
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total =>
      `${this.$t('electricBoxMock.message.total')} ${total} ${this.$t(
        'electricBoxMock.message.record',
      )}`,
  };
  countObj = {
    registerNumber: 0,
    onLineNumber: 0,
    offLineNumber: 0,
    unBindNumber: 0,
    bindNumber: 0,
  };

  mounted() {
    this.form.tenantId = this.tenant.pkId;
    this.form.tenantName = this.tenant.enterpriseName;
    this.searchParams.tenantId = this.tenant.pkId;
    this.searchParams.tenantName = this.tenant.enterpriseName;
    this.getProjectByTeantId();
    this.getDataList();
    this.getDeviceCount();
  }

  async warnSettings() {
    try {
      const result = await createFormModal(
        () => <WarnSettingsForm tenantID={this.tenant.pkId} />,
        {
          width: '650px',
          title: this.$t('electricBox.select.rules'),
        },
      );
      if (result) {
        // await this.getDataList();
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  filterDeviceList(item) {
    this.form.operationalStatus = item.value;
    this.handleSearchSubmit();
  }
  async getDeviceCount() {
    try {
      this.countObj = await deviceCounts();
    } catch {
      return false;
    }
  }
  loading = false;
  async getDataList() {
    this.tableData = [];
    const params = {
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
      ...this.searchParams,
    };
    this.loading = true;
    const { records, current, size, total } = await getDeviceList(params);
    this.loading = false;
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
    this.tableData = records;
  }

  @Debounce(500)
  handleSearchSubmit() {
    for (let key in this.form) {
      this.searchParams[key] = this.form[key] ? this.form[key] : undefined;
    }
    // if (this.searchParams.tenantId) {
    //   delete this.searchParams.tenantId;
    // }
    if (this.searchParams.projectId) {
      delete this.searchParams.projectId;
    }
    this.pagination.current = 1;
    this.getDataList();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('camera.order'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('electricBox.table.deviceCode'),
        dataIndex: 'deviceName',
        ellipsis: true,
        width: 180,
      },
      {
        align: 'left',
        title: this.$t('electricBox.table.deviceSecret'),
        dataIndex: 'deviceSecret',
        scopedSlots: { customRender: 'deviceSecret' },
        width: 160,
      },
      {
        title: this.$t('electricBox.table.operationalStatus'),
        dataIndex: 'operationalStatus',
        scopedSlots: { customRender: 'operationalStatus' },
        width: 160,
      },
      {
        title: this.$t('electricBox.table.projectName'),
        dataIndex: 'projectName',
        ellipsis: true,
      },
      {
        title: this.$t('electricBox.table.building'),
        width: 160,
        dataIndex: 'buildName',
      },
      {
        align: 'left',
        title: this.$t('electricBox.table.expireDate'),
        width: 180,
        dataIndex: 'expireDate',
        scopedSlots: { customRender: 'expireDate' },
      },
      {
        title: this.$t('iotScreenManage.operation'),
        fixed: 'right',
        key: 'operation',
        width: 210,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  tableData = [];
  handlePagination(data) {
    this.pagination = data;
    this.getDataList();
  }
  selectedRowKeys = [];
  selectedList = [];
  onSelectChange(selectedKeys, selectedRows) {
    this.selectedRowKeys = selectedKeys;
    this.selectedList = selectedRows;
  }

  handleReset() {
    this.form = {
      tenantId: this.tenant.pkId,
      tenantName: this.tenant.enterpriseName,
      projectId: undefined,
      projectName: undefined,
      operationalStatus: '',
      deviceName: '',
    };
    this.searchParams = {
      tenantId: this.tenant.pkId,
      tenantName: this.tenant.enterpriseName,
    };
    this.getDataList();
  }
  enterpriseList = [];
  tenantSearchInput = '';
  projectSearchInput = '';
  projects = [];
  // async handTenantChange(id) {
  //   this.form.tenantId = id;
  //   const idx = this.enterpriseList.findIndex(item => item.id === id);
  //   this.form.tenantName = this.enterpriseList[idx].name;
  //   this.projects = [];
  //   this.form.projectId = undefined;
  //   await this.getProjectByTeantId();
  // }
  handProjectChange(id) {
    if (id) {
      const idx = this.projects.findIndex(item => item.id === id);
      this.form.projectName = this.projects[idx].name;
    } else {
      this.form.projectName = undefined;
      this.projects =
        (this.oProjects && this.oProjects.concat()) || this.projects;
    }
    this.handleSearchSubmit();
  }
  async getProjectByTeantId() {
    const projectParams = {
      'query.size': 30,
    };
    if (this.projectSearchInput) {
      projectParams.keyword = this.projectSearchInput;
    }
    let records = await getRelatedProject(projectParams);
    if (!projectParams.keyword) {
      records = records.splice(0, 100);
    }
    records = records.map(item => {
      return {
        id: item.projectId,
        name: item.projectName,
      };
    });
    if (!projectParams.keyword) {
      this.oProjects = records;
    }
    this.projects = records;
  }
  @Debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input.toLowerCase();
    this.getProjectByTeantId();
  }
  get emptyLocale() {
    return {
      emptyText: <Empty />,
    };
  }
  async batchInform() {
    const obj = this.selectedList.map(item => {
      return {
        buildId: item.buildId,
        deviceName: item.deviceName,
      };
    });
    try {
      const result = await createFormModal(
        () => <BatchInform informItems={obj} />,
        {
          width: '442px',
          title: this.$t('electricBox.form.batchNotifications'),
        },
      );
      if (result) {
        this.selectedRowKeys = [];
        await this.getDataList();
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }
  async expiredTips(obj) {
    const text = this.$t('electricBox.form.expiredTip');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('camera.operationTips'),
          okText: this.$t('electricBox.form.toSet'),
          onOk: () => this.toConfiguration(obj, '3'), // 配置-安全配置
        },
      );
    } catch {
      return false;
    }
  }
  async bindDevice(obj) {
    const isExpired = obj.isExpire;
    if (!isExpired) {
      try {
        obj.idxTenantId = this.tenant.pkId;
        const result = await createFormModal(
          () => <BindDevice editData={obj} />,
          {
            width: '442px',
            title: this.$t('electricBox.title.bindDevice'),
          },
        );
        if (result) {
          await this.getDataList();
        }
      } catch (error) {
        if (error.message !== 'user cancel') this.$message.error(error.message);
      }
    } else {
      await this.expiredTips(obj);
    }
  }
  async unBindOrRemove(record, flag) {
    const text =
      flag === 'unbind'
        ? this.$t('electricBox.form.unbindTip1')
        : this.$t('electricBox.form.removeTip1');
    const tips =
      flag === 'unbind'
        ? this.$t('electricBox.form.unbindTip2')
        : this.$t('electricBox.form.removeTip2');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('electricBox.title.importantNote'),
          onOk: () => {
            this.socketUnbind(record.id, flag);
          }, // 解绑 删除
        },
      );
    } catch {
      return false;
    }
  }
  async socketUnbind(id, flag) {
    let params = {
      bindDeviceIds: [id],
      operatingDeviceInfo: window.navigator.userAgent,
    };
    if (flag === 'unbind') {
      await unBindDevice(params);
      await this.getDataList();
    } else {
      await deleteDevice(params);
      if (this.pagination.current > 1) {
        if (this.tableData.length === 1) {
          this.pagination.current--;
        }
      } else {
        this.pagination.current = 1;
      }
      await this.getDataList();
    }
    this.$message.success(
      this.$t('electricBox.form.operate', {
        value: this.$t('hat.electronFence.success'),
      }),
    );
  }
  async unLock(record) {
    const lockType = record.operationalStatus;
    const text =
      lockType === 'LOCKED'
        ? this.$t('electricBox.form.lockTip1', {
            value: `${record.lockUserName}（${record.lockUserPhone}）`,
          })
        : this.$t('electricBox.form.lockTip2');
    const tips = this.$t('electricBox.form.lockTip3');
    const okText =
      lockType === 'LOCKED'
        ? this.$t('electricBox.form.confirmUnlocking')
        : this.$t('msg.confirm');
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span style="color: #F72F2F">{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('electricBox.form.operate', {
            value: this.$t('common.tips.prompt'),
          }),
          okText,
          onOk: () => {
            if (record.operationalStatus === 'LOCKED')
              this.unLockDevice(record);
          },
        },
      );
    } catch {
      return false;
    }
  }
  async unLockDevice(record) {
    try {
      await deviceLock({
        buildingName: record.buildName,
        idxBuildingId: record.buildId,
        idxProjectId: record.projectId,
        projectName: record.projectName,
        idxTenantId: record.idxTenantId,
        tenantName: record.idxTenantName,
        data: {
          deviceName: record.deviceName,
          flag: 0,
        },
      });
      this.$message.success(this.$t('electricBox.form.unlockSuccessfully'));
      await this.getDataList();
    } catch {
      return false;
    }
  }
  handleCloseModalDrawer() {
    this.modalDrawer.handleClosed();
  }
  async manageBox(record) {
    const isExpired = record.isExpire;
    if (!isExpired) {
      if (record.existBindRecord) {
        this.modalDrawer = createDrawer(
          () => (
            <DeviceManage data={record} close={this.handleCloseModalDrawer} />
          ),
          {
            title: this.$t('electricBox.mg.title'),
            width: '900px',
            wrapClassName: 'deviceManage',
          },
        );
      } else {
        this.$message.info(this.$t('electricBox.mg.info'));
      }
    } else {
      await this.expiredTips(record);
    }
  }

  @Debounce(500)
  async handleLink(record) {
    try {
      const result = await backendGenerate({
        projectId: record.projectId,
        password: '',
        effectiveDate: 'ONE',
        type: 'PROJECT',
      });
      localStorage.setItem('IOT_SCREEN_PROJECT_ID', record.projectId);
      window.open(
        `${createModuleUrl('iot-max')}/iot/generalScreen/projectProof/${
          result.id
        }?t=backend`,
      );
    } catch {
      return false;
    }
  }
  setConfiguration(obj) {
    const isExpired = obj.isExpire;
    if (isExpired) {
      this.expiredTips(obj);
    } else {
      if (obj.buildId) {
        this.toConfiguration(obj);
      } else {
        this.$message.info(this.$t('electricBox.form.setConfig'));
      }
    }
  }
  configurationModalDrawer = undefined;
  async toConfiguration(obj, activeKey = '1') {
    this.configurationModalDrawer = createDrawer(
      () => (
        <DeviceConfiguration defaultActivityKey={activeKey} configData={obj} />
      ),
      {
        title: this.$t('electricBox.title.deviceSetting'),
        width: '720px',
        wrapClassName: 'camera-box-list',
        onClose: this.closeConfigurationDrawer,
      },
    );
  }
  closeConfigurationDrawer() {
    this.configurationModalDrawer.handleClosed();
    this.getDataList();
  }
  async copy(item) {
    const str =
      `设备码：${item.deviceName}\n` +
      `验证码：${item.deviceSecret}\n` +
      `已绑定项目：${item.projectName}\n` +
      `已绑定楼栋：${item.buildName}`;
    await clipboardWrite(str);
    this.$message.success(this.$t('camera.replicatingSuccess'));
    return item.text;
  }
  @Debounce(500)
  async exportDevices() {
    try {
      await exportDeviceList(this.form);
      this.$message.success(this.$t('common.exportSuccessful'));
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.main {
  .topData {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .item {
      width: 18%;
      height: 116px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .textDiv {
        color: #fff;
        text-align: center;
        width: 200px;
        .numberDiv {
          font-weight: 500;
          font-size: 32px;
        }
        .numberTextDiv {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .iconDiv {
        position: absolute;
        right: 15px;
        background-color: white;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle {
          font-size: 50px;
        }
      }
    }
  }
  .search {
    display: flex;
    justify-content: space-between;
    .item {
      display: inline-block;
      margin-right: 15px;
    }
    .btn {
      > button {
        margin: 0 0 10px 10px;
      }
    }
    :global .ant-form-item {
      margin-bottom: 15px;
      .ant-form-item-label > label:after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }
  }
  .buttonGroups {
    display: flex;
    justify-content: flex-start;
    .red {
      color: red;
    }
    .button {
      width: 44px;
      position: relative;
      .messageCount {
        position: absolute;
        font-size: 12px;
        top: 1px;
        right: 5px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        background: var(--font-active);
        color: var(--table-row);
        text-align: center;
      }
    }
  }
  .statusOnline {
    color: #41c400;
  }
  .statusOffline {
    color: red;
  }
  .statusTobeBinding {
    color: #ffa348;
  }
  .statusFen {
    color: #02a7f0;
  }
  .statusBind {
    color: #516cf0;
  }
  .statusLocked {
    color: #999;
  }
  .actived {
    color: #41c400;
  }
  .notActived {
    color: #999;
  }
  .copy {
    margin-left: 10px;
    color: var(--primary);
    cursor: pointer;
  }
  :global {
    .ant-spin-container > .ant-table {
      flex: none;
    }
    .ant-tooltip-arrow::before {
      background-color: white;
    }
    .ant-tooltip-inner {
      color: black;
      background-color: white;
    }
  }
}
:global(.camera-box-list .ant-drawer-body) {
  padding: 0 !important;
}
:global {
  .deviceManage {
    .ant-drawer-header {
      .ant-drawer-close {
        left: 0;
      }
    }
    .feedBackBtn {
      position: fixed;
      top: 8px;
      right: 30px;
      z-index: 999;
      color: #ddd;
      font-size: 16px;
      cursor: pointer;
      .anticon {
        margin-right: 8px;
      }
    }
  }
}

.wrap {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }
  :global {
    .ant-table-wrapper .ant-spin-container .ant-table-body,
    .ant-table-wrapper .ant-table-scroll .ant-table-body {
      flex: 1;
      overflow: auto !important;
      max-height: none !important;
    }
  }
}
.content {
  padding: 20px 20px;
}
.searchInput {
  margin-bottom: 20px;
  input {
    width: 110px;
    transition: width 200ms linear;
    &:focus {
      width: 200px;
    }
  }
}
.table {
  :global(.ant-pagination.ant-table-pagination) {
    width: 100%;
    text-align: right;
  }
  :global(.ant-pagination-options-size-changer) {
    position: absolute;
    left: 60px;
    bottom: -46px;
    &::before {
      content: '每页显示';
      display: block;
      position: absolute;
      top: 50%;
      left: -60px;
      transform: translateY(-50%);
    }
  }
}
</style>
